<script>
import { ref, onMounted, computed, inject, watch } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import Pagination from "@/components/admin/pagination.vue";
import subHeader from "@/components/admin/subHeader.vue";
import tabHeader from "@/components/admin/tabHeader.vue";
import operateTableModal from "@/components/admin/operateTableModal.vue";
import scheduleModal from '@/components/admin/eventSettingTablesModal.vue';
import bisatStatus from "@/assets/js/bisatStatus.json";
import bisatLang from "@/assets/js/bisatLang.json";
import dropdownOperationStatus from "@/components/admin/dropDown/dropDownOperationStatus.vue";
export default {
  name: "eventSettingTables",
  props: {
    event: Object
  },
  components: {
    Pagination,
    subHeader,
    tabHeader,
    operateTableModal,
    scheduleModal,
    dropdownOperationStatus,
  },
  setup(props) {
    const axios = inject("axios"); // $axios 주입 받기
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref("");
    const activeSub = ref("operate");
    const activeHeadTab = ref("operate");

    //이벤트 일시 관련 변수
    const eventDateList = ref([]);
    const eventDateTime = ref([]);
    const { eventTitle, selectDate, handleSelectDate } = props.event;

    //이벤트 기준 관련 변수 (Y축)
    const yList = ref([]);

    // 스케줄 관련 변수
    const schedule = ref([]);

    // 모달 관련 변수 start
    const showStatusModal = ref(false);
    const selectedUserData = ref({});
    const selectedScheduleData = ref({});
    const showScheduleAddModal = ref(false);

    const closeAllModals = () => {
      showStatusModal.value = false;
      showScheduleAddModal.value = false;
    };

    const openOperateModal = (type, userData) => {
      // 유저 정보 모달 열기
      const statusKey = `${type.toLowerCase()}_status`;
      const idKey = `${type.toLowerCase()}_id`;

      const scheduleId = userData["schedule_id"];
      const userStatus = userData[statusKey] || "";
      const userId = userData[idKey] || null;

      selectedUserData.value = { type, userStatus, userId, scheduleId };
      showStatusModal.value = true;
    };

    const openScheduleAddModal = (schedule, time, isSchedule) => {
      selectedScheduleData.value = { schedule, time, isSchedule };
      showScheduleAddModal.value = true;
    }
    // 모달 관련 변수 end

    // PAGINATION 관련 변수
    const currentPage = ref(1);
    const itemsPerPage = ref(10); // 페이지당 아이템 수

    const totalPages = computed(() => Math.ceil(yList.value.length / itemsPerPage.value));
    const paginatedYList = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return yList.value.slice(start, end);
    });
    const handleClickPage = (pageIndex) => {
      if (pageIndex >= 1 && pageIndex <= totalPages.value) {
        currentPage.value = pageIndex;
      }
    };

    // STEP1. 이벤트 날짜 리스트 받아오기
    async function fetchDate() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
        });

        const url = `/schedule/read_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateList.value = response.data.date_list;
          if (!selectDate.value) {
            selectDate.value = eventDateList.value[0];
          }
          fetchDateTime();
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }

    // STEP2. 이벤트 세팅 시 설정한 상담회 날짜, 시간 불러오기 (X축 생성)
    async function fetchDateTime() {
      store.commit('eventStore/setEventDate', selectDate.value)
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectDate.value,
        });

        const url = `/schedule/read_datetime?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateTime.value = response.data.datetime.map((item) => ({
            ...item,
            start_time: item.start_time.slice(0, 5), // HH:MM 형식으로 변환
            end_time: item.end_time.slice(0, 5), // HH:MM 형식으로 변환
            showBool: true, // 스케줄 숨기기 boolean
          }));
          if (selectedType.value == 'booth_name') {
            fetchBoothList();
          } else if (selectedType.value == 'buyer_name') {
            fetchBISList('buyer');
          } else if (selectedType.value == 'seller_name') {
            fetchBISList('seller');
          } else if (selectedType.value == 'interpreter_name') {
            fetchBISList('interpreter');
          }
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }

    // STEP3. 부스 불러오기 (Y축 생성)
    async function fetchBoothList() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
        });

        const url = `/schedule/read_booths?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          yList.value = response.data.booths;
          fetchSchedule();
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }
    // STEP3. 기준으로 보기 추가 - BIS 불러오기 (Y축 생성)
    async function fetchBISList(type) {
      try {
        const queryParams = new URLSearchParams();
        // 선택된 타입에 따라 쿼리 파라미터 추가
        if (type === 'buyer') {
          queryParams.append('account_type_list', 'buyer');
        } else if (type === 'interpreter') {
          queryParams.append('account_type_list', 'interpreter');
        } else if (type === 'seller') {
          queryParams.append('account_type_list', 'seller');
        }
        queryParams.append('piece_event_id', eventId.value);
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          yList.value = response.data.user_list;
          fetchSchedule();
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    // STEP4. 스케줄 불러오기 (데이터 추가)
    async function fetchSchedule(closeStatus = false) {
      // BIS별 상태 드롭다운 닫기위한 파라미터로 closeStatus 추가, 필요시에만 true로 넣어주기
      if (closeStatus) {
        statusBuyer.value.openSelect(true);
        statusInterpreter.value.openSelect(true);
        statusSeller.value.openSelect(true);
      }
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          search_by: searchBy.value,
          search_text: searchText.value,
          date: selectDate.value,
          limit: 10000,
        });
        if (!optionsBool.value) {
          // 옵션이 모두 선택되어있으면 파라미터 보내지 않음
          for (const status of answersBUYER.value) {
            queryParams.append('buyer_status_list', status.status);
          }
          for (const status of answersSELLER.value) {
            queryParams.append('seller_status_list', status.status);
          }
          for (const status of answersINTERPRETER.value) {
            queryParams.append('interpreter_status_list', status.status);
          }
        }

        const url = `/schedule/read_piece_to_schedule?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.length > 0) {
          schedule.value = response.data.map(item => {
            item.checkedStatus = false;
            return item;
          });
        } else {
          schedule.value = [];
          if (searchText.value) {
            alert("검색결과가 없습니다.")
          }
        }
      } catch (error) {
        console.error("스케줄 불러올때 오류:", error);
      }
    }

    // 펑션바 기능들 start
    const selectedType = ref("booth_name");
    const searchBy = ref("interpreter_name");
    const searchText = ref("");
    const searchByLabel = (searchBy) => {
      switch (searchBy) {
        case 'booth_name':
          return "부스명"
        case 'buyer_name':
          return "바이어명"
        case 'interpreter_name':
          return "통역사명"
        case 'seller_name':
          return "셀러명"
        default:
          return "-"
      }
    }
    const statusBuyer = ref(null);
    const statusInterpreter = ref(null);
    const statusSeller = ref(null);
    const optionsBUYER = bisatStatus["BUYER"]["operate"];
    const optionsINTERPRETER = bisatStatus["INTERPRETER"]["operate"];
    const optionsSELLER = bisatStatus["SELLER"]["operate"];
    const answersBUYER = ref(bisatStatus["BUYER"]["operate"]);
    const answersINTERPRETER = ref(bisatStatus["INTERPRETER"]["operate"]);
    const answersSELLER = ref(bisatStatus["SELLER"]["operate"]);

    const changeCheckValue = (BIS, options) => {
      if (BIS == "BUYER") {
        answersBUYER.value = options;
      } else if (BIS == "INTERPRETER") {
        answersINTERPRETER.value = options;
      } else if (BIS == "SELLER") {
        answersSELLER.value = options;
      }
    };

    const resetAnswers = () => {
      answersBUYER.value = optionsBUYER;
      answersINTERPRETER.value = optionsINTERPRETER;
      answersSELLER.value = optionsSELLER;
      fetchSchedule(true);
    }

    const optionsBool = computed(() => {
      return optionsBUYER.length === answersBUYER.value.length &&
        optionsINTERPRETER.length === answersINTERPRETER.value.length &&
        optionsSELLER.length === answersSELLER.value.length;
    });
    // 펑션바 기능들 end

    const getSchedulesByBooth = (ptb_id, ptd_id) => {
      // 부스별로 보기 가공 - fetchSchedule에서 뽑은 데이터의 부스 정보와 스케줄 정보가 동일한지 확인
      return schedule.value.filter((s) => s.ptb_id === parseInt(ptb_id) && s.ptd_id === parseInt(ptd_id));
    };

    const getSchedulesByBIS = (account_id, ptd_id, BIS) => {
      // BIS별로 보기 가공 - fetchSchedule에서 뽑은 데이터의 회원 정보와 스케줄 정보가 동일한지 확인
      if (BIS == 'buyer_name') {
        return schedule.value.filter((s) => s.buyer_id === parseInt(account_id) && s.ptd_id === parseInt(ptd_id));
      } else if (BIS == 'seller_name') {
        return schedule.value.filter((s) => s.seller_id === parseInt(account_id) && s.ptd_id === parseInt(ptd_id));
      } else if (BIS == 'interpreter_name') {
        return schedule.value.filter((s) => s.interpreter_id === parseInt(account_id) && s.ptd_id === parseInt(ptd_id));
      }
    };

    const getShowEventTime = () => {
      if (scheduleToggleBool.value) {
        return eventDateTime.value.filter(t => t.showBool == true);
      } else {
        return eventDateTime.value;
      }
    }

    const scheduleDownload = async () => {
      try {
        const response = await axios.get(`/schedule/download_schedules_table?piece_event_id=${eventId.value}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
          responseType: "blob", // 중요: 파일을 다운로드할 때는 responseType을 'blob'으로 설정해야 합니다.
        });
        // 파일 객체 생성
        const blob = new Blob([response.data], { type: response.headers["content-type"] });

        // 파일 URL 생성
        const url = URL.createObjectURL(blob);
        // 현재 날짜와 시간을 포맷
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        // 파일 다운로드를 트리거하기 위해 링크를 생성하고 클릭
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `schedule_table${formattedDateTime}.xlsx`); // 파일명에 날짜와 시간을 추가

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 메모리 해제
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("파일 다운로드 중 오류 발생:", error);
      }
    };

    const getIconClass = (type, category, status) => {
      const categoryData = bisatStatus[type][category];
      const statusItem = categoryData.find((item) => item.status === status);
      return statusItem ? statusItem.icon : "";
    };

    // 스케줄 숨기기 start
    const scheduleToggleBool = ref(false); // 스케줄 숨기기 onoff
    const setScheduleShow = date => {
      const index = eventDateTime.value.findIndex(t => date.ptd_id === t.ptd_id);
      if (eventDateTime.value[index].showBool) {
        eventDateTime.value[index].showBool = false;
      } else {
        eventDateTime.value[index].showBool = true;
      }
    }
    const escapeScheduleShow = () => {
      eventDateTime.value.map(item => {
        item.showBool = true;
      });
      scheduleToggleBool.value = false;
    }

    const langLabel = val => {
      // 언어명 반환
      if (val != null) {
        const lang = bisatLang.find(l => l.code === val);
        return lang.label
      } else {
        return '  ';
      }
    }
    // 스케줄 숨기기 end

    // 상태 일괄 변경 start
    const openBatchChange = ref(false);
    const oBCFocus = ref(null);
    const oBCBHovering = ref(false);
    const oBCIHovering = ref(false);
    const oBCSHovering = ref(false);

    const openoBC = () => {
      if (openBatchChange.value == true) {
        return false;
      } else {
        oBCFocus.value.focus();
      }
    };

    const blurCloseoBC = () => {
      // 드롭다운 자신 눌렀을때 닫히도록 타임아웃 걸기
      // 타임아웃 없으면 자신 눌렀을 때 닫기는 척하다 다시 켜짐
      setTimeout(() => {
        openBatchChange.value = false;
      }, 90);
    };

    const batchChangeBool = ref(false); // 일괄 선택 모드 onoff
    const batchChangeType = ref(''); // BIS 선택
    const batchChangeStatus = ref({}); // 선택된 상태 (radio)

    // 모드 변경
    const batchChange = type => {
      batchChangeBool.value = true;
      batchChangeType.value = type;
    }

    // 변경할 유저 onoff
    const setBatchStatus = sche => {
      const index = schedule.value.findIndex(s => sche.schedule_id === s.schedule_id);
      if (schedule.value[index].checkedStatus) {
        schedule.value[index].checkedStatus = false;
      } else {
        schedule.value[index].checkedStatus = true;
      }
    }

    // 변경할 유저 계정 수
    const batchLength = computed(() => {
      const array = schedule.value.filter(s => s.checkedStatus === true);
      return array.length;
    })

    // 일괄 변경 상태 저장
    const changeSwitchValue = value => {
      batchChangeStatus.value = value;
    }

    // 상태 업데이트
    const submitSwitchValue = async () => {
      const array = schedule.value.filter(s => s.checkedStatus === true);
      const userList = [];
      array.forEach(item => {
        let accountId = '';
        if (batchChangeType.value == 'buyer') {
          accountId = item.buyer_id;
        } else if (batchChangeType.value == 'interpreter') {
          accountId = item.interpreter_id;
        } else if (batchChangeType.value == 'seller') {
          accountId = item.seller_id;
        }
        userList.push({
          piece_event_id: eventId.value,
          account_id: accountId,
          account_type: batchChangeType.value,
          schedule_id: item.schedule_id,
          code_name: batchChangeStatus.value.status,
          created_how: 'SCHEDULETABLE',
        })
      });
      const data = { "update_list": userList };
      const url = '/schedule/update_schedule_account_status';
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.data.result === true) {
          await fetchSchedule();
          if (batchChangeType.value == 'buyer') {
            alert(`선택한 스케줄의 바이어 상태가 ${batchChangeStatus.value.label}로 일괄 변경되었습니다.`)
          } else if (batchChangeType.value == 'interpreter') {
            alert(`선택한 스케줄의 통역사 상태가 ${batchChangeStatus.value.label}로 일괄 변경되었습니다.`)
          } else if (batchChangeType.value == 'seller') {
            alert(`선택한 스케줄의 셀러 상태가 ${batchChangeStatus.value.label}로 일괄 변경되었습니다.`)
          }
          escapeBatchShow()
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('상태 업데이트 오류:', error);
      }
    }

    // 초기화
    const escapeBatchShow = () => {
      batchChangeBool.value = false;
      batchChangeStatus.value = {};
    }
    // 상태 일괄 변경 end

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: "home" }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        fetchDate();
      }
    });

    return {
      activeSub,
      activeHeadTab,
      eventId,
      statusBuyer,
      statusInterpreter,
      statusSeller,
      optionsBUYER,
      optionsSELLER,
      optionsINTERPRETER,
      answersBUYER,
      answersSELLER,
      answersINTERPRETER,
      resetAnswers,
      optionsBool,
      searchBy,
      searchText,
      fetchSchedule,
      changeCheckValue,
      eventDateList,
      fetchDateTime,
      selectDate,
      handleSelectDate,
      eventDateTime,
      yList,
      getSchedulesByBooth,
      getSchedulesByBIS,
      getShowEventTime,
      showStatusModal,
      selectedUserData,
      showScheduleAddModal,
      selectedScheduleData,
      openOperateModal,
      openScheduleAddModal,
      selectedType,
      searchByLabel,
      closeAllModals,
      scheduleDownload,
      currentPage,
      itemsPerPage,
      totalPages,
      paginatedYList,
      handleClickPage,
      getIconClass,
      scheduleToggleBool,
      setScheduleShow,
      escapeScheduleShow,
      langLabel,
      openBatchChange,
      oBCFocus,
      oBCBHovering,
      oBCIHovering,
      oBCSHovering,
      openoBC,
      blurCloseoBC,
      batchChangeBool,
      batchChangeType,
      batchChangeStatus,
      batchChange,
      setBatchStatus,
      batchLength,
      changeSwitchValue,
      escapeBatchShow,
      submitSwitchValue,
      schedule
    };
  },
};
</script>

<template>
  <div id="operationTables" class="back_light_gray2">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="wide_wrap">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
      </div>
      <div class="tables_wrap eventSettingTables_wrap operate">
        <div class="function_bar">
          <div class="row">
            <select v-model="selectDate" @change="handleSelectDate">
              <option v-for="(item, index) in eventDateList" :key="index" :value="item">{{ item }}</option>
            </select>
            <div class="cont_wrap ds_flex gap18 al_center">
              <p class="icon_box" @click="scheduleDownload"><i class="icon-download"></i></p>
              <p class="fake_textbox">
                <select name="searchBy" id="searchBy" v-model="searchBy">
                  <option value="buyer_name">바이어</option>
                  <option value="interpreter_name">통역사</option>
                  <option value="seller_name">셀러</option>
                  <option value="booth_name">부스</option>
                </select>
                <input type="text" id="searchText" :placeholder="`${searchByLabel(searchBy)} 검색어 입력`"
                  v-model="searchText" @keyup.enter="fetchSchedule(true)" />
                <template v-if="searchText != ''">
                  <i class="icon-delete secondary_text cs_pointer" @click="searchText = ''; fetchSchedule(true)"></i>
                </template>
                <template v-else>
                  <i class="icon-search secondary_text cs_pointer"></i>
                </template>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="user_table_top_cont ds_flex al_center">
              <label class="fx_name">기준으로 보기</label>
              <div class="radio_wrap back_light_gray">
                <div class="radio_option">
                  <input type="radio" id="radio_booth" name="user_type" value="booth_name" v-model="selectedType"
                    @change="fetchDateTime()" />
                  <label for="radio_booth">부스</label>
                </div>
                <div class="radio_option">
                  <input type="radio" id="radio_buyer" name="user_type" value="buyer_name" v-model="selectedType"
                    @change="fetchDateTime()" />
                  <label for="radio_buyer">바이어</label>
                </div>
                <div class="radio_option">
                  <input type="radio" id="radio_seller" name="user_type" value="seller_name" v-model="selectedType"
                    @change="fetchDateTime()" />
                  <label for="radio_seller">셀러</label>
                </div>
                <div class="radio_option">
                  <input type="radio" id="radio_interpreter" name="user_type" value="interpreter_name"
                    v-model="selectedType" @change="fetchDateTime()" />
                  <label for="radio_interpreter">통역사</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="ds_flex al_center gap12">
              <label class="fx_name">상태 보기</label>
              <dropdown-operation-status id="statusBuyer" ref="statusBuyer" mode="filter" :options="optionsBUYER"
                :answers="answersBUYER" @change="(answers) => {
                  changeCheckValue('BUYER', answers);
                }
                  "></dropdown-operation-status>
              <dropdown-operation-status id="statusInterpreter" ref="statusInterpreter" mode="filter"
                :options="optionsINTERPRETER" :answers="answersINTERPRETER" @change="(answers) => {
                  changeCheckValue('INTERPRETER', answers);
                }
                  "></dropdown-operation-status>
              <dropdown-operation-status id="statusSeller" ref="statusSeller" mode="filter" :options="optionsSELLER"
                :answers="answersSELLER" @change="(answers) => {
                  changeCheckValue('SELLER', answers);
                }
                  "></dropdown-operation-status>
              <transition name="dropdown"><!-- opacity만 작동하는 트렌지션 이름 -->
                <div class="ds_flex gap12 al_center" v-if="!optionsBool">
                  <div class="inline_cont_btn change_all_status" @click="fetchSchedule(true)">적용하기</div>
                  <i class="icon-refresh sub_text cs_pointer" @click="resetAnswers()"></i>
                </div>
              </transition>
            </div>
            <div class="ds_flex gap12 al_center" v-if="!scheduleToggleBool && !batchChangeBool">
              <div class="inline_cont_btn change_all_status" @click="scheduleToggleBool = true">스케줄 숨기기</div>
              <div class="fake_dropdown_wrap">
                <input type="text" ref="oBCFocus" name="dropDownOpener" @focus="openBatchChange = true"
                  @blur="blurCloseoBC()" class="focus_input" />
                <div class="inline_cont_btn change_all_status" @click="openoBC();">상태 일괄 변경하기</div>
                <transition name="dropdown">
                  <div class="fake_dropdown_options" v-if="openBatchChange" style="width: 140px">
                    <div class="fake_option" @mouseover="oBCBHovering = true" @mouseout="oBCBHovering = false"
                      @click="batchChange('buyer')">
                      바이어
                      <i :class="oBCBHovering ? 'icon-checked' : ''"></i>
                    </div>
                    <div class="fake_option" @mouseover="oBCIHovering = true" @mouseout="oBCIHovering = false"
                      @click="batchChange('interpreter')">
                      통역사
                      <i :class="oBCIHovering ? 'icon-checked' : ''"></i>
                    </div>
                    <div class="fake_option" @mouseover="oBCSHovering = true" @mouseout="oBCSHovering = false"
                      @click="batchChange('seller')">
                      셀러
                      <i :class="oBCSHovering ? 'icon-checked' : ''"></i>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <div class="function_bar border_top" v-if="scheduleToggleBool">
          <div class="row al_center">
            <div class="secondary_text size_14">숨길 스케줄을 선택하세요. 선택된 스케줄은 가려져요.</div>
            <div class="ds_flex al_center gap12">
              <div class="inline_cont_btn active_btn b_700 size_14" style="cursor: default">스케줄 숨기기</div>
              <div class="inline_cont_btn sub_text b_600 size_12" @click="escapeScheduleShow()">나가기</div>
            </div>
          </div>
        </div>
        <div class="function_bar border_top" v-if="batchChangeBool">
          <div class="row al_center">
            <div class="secondary_text size_14">상태를 변경할 셀을 선택하세요</div>
            <div class="ds_flex al_center gap12">
              <div class="b_700 size_14 eqqui_text">{{ batchLength }}명</div>
              <dropdown-operation-status v-if="batchChangeType == 'buyer'" id="batchBuyer" mode="status"
                :options="optionsBUYER" :radioAnswer="batchChangeStatus" @switch="(answer) => {
                  changeSwitchValue(answer);
                }
                  " @submit="submitSwitchValue()"></dropdown-operation-status>
              <dropdown-operation-status v-if="batchChangeType == 'interpreter'" id="batchInterpreter" mode="status"
                :options="optionsINTERPRETER" :radioAnswer="batchChangeStatus" @switch="(answer) => {
                  changeSwitchValue(answer);
                }
                  " @submit="submitSwitchValue()"></dropdown-operation-status>
              <dropdown-operation-status v-if="batchChangeType == 'seller'" id="batchSeller" mode="status"
                :options="optionsSELLER" :radioAnswer="batchChangeStatus" @switch="(answer) => {
                  changeSwitchValue(answer);
                }
                  " @submit="submitSwitchValue()"></dropdown-operation-status>
              <div class="inline_cont_btn sub_text b_600 size_12" @click="escapeBatchShow()">나가기</div>
            </div>
          </div>
        </div>
        <div class="table_wrap">
          <table>
            <thead>
              <tr>
                <!-- TODO: @Shim-jung-min-lx 오름차순, 내림차순 추가 -->
                <th>{{ searchByLabel(selectedType) }}<span>▾</span></th>
                <th v-for="(date, index) in getShowEventTime()" :key="date.ptd_id"
                  :class="scheduleToggleBool ? 'cs_pointer' : ''" @click="setScheduleShow(date, index)">
                  <template v-if="scheduleToggleBool">
                    <label :for="`ShowBool${date.ptd_id}`" class="fake_checkbox" :class="{ 'active': date.showBool }">
                      <i v-if="date.showBool" class="icon-checked"></i>
                    </label>
                    <label :for="`ShowBool${date.ptd_id}`" class="white_text">
                      {{ date.start_time }}~{{ date.end_time }}
                    </label>
                  </template>
                  <template v-else>
                    {{ date.start_time }}~{{ date.end_time }}
                  </template>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="paginatedYList.length > 0">
                <!-- 부스기준 start -->
                <template v-if="selectedType == 'booth_name'">
                  <tr v-for="(booth, index) in paginatedYList" :key="index">
                    <td class="booth">{{ booth.booth_name }}</td>
                    <td v-for="time in getShowEventTime()" :key="time.ptd_id">
                      <template v-if="getSchedulesByBooth(booth.ptb_id, time.ptd_id).length">
                        <div class="schedule_info_wrap"
                          v-for="schedule in getSchedulesByBooth(booth.ptb_id, time.ptd_id)"
                          :key="schedule.schedule_id">
                          <label v-if="batchChangeBool" :for="`statusChange${schedule.schedule_id}`" class="cs_pointer">
                            <label :for="`statusChange${schedule.schedule_id}`" class="fake_checkbox"
                              :class="{ 'active': schedule.checkedStatus }">
                              <i v-if="schedule.checkedStatus" class="icon-checked"></i>
                            </label>
                            <input type="checkbox" :id="`statusChange${schedule.schedule_id}`"
                              :value="schedule.checkedStatus" @click="setBatchStatus(schedule)">
                          </label>
                          <p class="schedule_info" @click="openScheduleAddModal(schedule, time, schedule.schedule_id)">
                            <i class="icon-operate_remark" :class="schedule.schedule_memo != null ? 'memo' : ''"></i>
                            {{ schedule.manager_name }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('BUYER', schedule)">
                            <span class="type_bdg">B</span>
                            <i class="icon_box"
                              :class="`icon-${getIconClass('BUYER', 'operate', schedule.buyer_status)}`"></i>
                            {{ schedule.buyer_company }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('INTERPRETER', schedule)">
                            <span class="type_bdg">I</span>
                            <i class="icon_box"
                              :class="`icon-${getIconClass('INTERPRETER', 'operate', schedule.interpreter_status)}`"></i>
                            {{ schedule.interpreter_name }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('SELLER', schedule)">
                            <span class="type_bdg">S</span>
                            <i class="icon_box"
                              :class="`icon-${getIconClass('SELLER', 'operate', schedule.seller_status)}`"></i>
                            {{ schedule.seller_id }} / {{ schedule.seller_company }}
                          </p>
                        </div>
                      </template>
                      <template v-else>
                        <p class="add_schedule"></p>
                      </template>
                    </td>
                  </tr>
                </template>
                <!-- 부스기준 end -->
                <!-- BIS 기준 start -->
                <template v-else>
                  <tr v-for="(user, index) in paginatedYList" :key="index">
                    <td class="booth">
                      <div v-if="selectedType == 'buyer_name'">
                        <p>{{ user.full_name }}</p>
                        <span class="sub_info">{{ user.company }}</span>
                      </div>
                      <div v-if="selectedType == 'interpreter_name'">
                        <p>{{ user.full_name }}</p>
                        <span class="sub_info">
                          <template v-if="user.lang1 != null">{{ langLabel(user.lang1) }}</template><template
                            v-else>-</template>
                          <template v-if="user.lang2 != null"> | {{ langLabel(user.lang2) }}</template>
                        </span>
                      </div>
                      <div v-if="selectedType == 'seller_name'">
                        {{ user.company }}
                      </div>
                    </td>
                    <td v-for="time in getShowEventTime()" :key="time.ptd_id">
                      <template v-if="getSchedulesByBIS(user.account_id, time.ptd_id, selectedType).length">
                        <div class="schedule_info_wrap"
                          v-for="schedule in getSchedulesByBIS(user.account_id, time.ptd_id, selectedType)"
                          :key="schedule.schedule_id">
                          <p v-if="batchChangeBool" @click="setBatchStatus(schedule)">
                            <label :for="`statusChange${schedule.schedule_id}`" class="fake_checkbox"
                              :class="{ 'active': schedule.checkedStatus }">
                              <i v-if="schedule.checkedStatus" class="icon-checked"></i>
                            </label>
                            <input type="checkbox" :id="`statusChange${schedule.schedule_id}`">
                          </p>
                          <p class="schedule_info" @click="openScheduleAddModal(schedule, time, schedule.schedule_id)">
                            <i class="icon-operate_remark" :class="schedule.schedule_memo != null ? 'memo' : ''"></i>
                            {{ schedule.ptb }} / {{ schedule.manager_name }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('BUYER', schedule)">
                            <span class="type_bdg">B</span><i class="icon_box"
                              :class="`icon-${getIconClass('BUYER', 'operate', schedule.buyer_status)}`"></i> {{
                                schedule.buyer_company }}
                            {{ schedule.buyer_id }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('INTERPRETER', schedule)">
                            <span class="type_bdg">I</span><i class="icon_box"
                              :class="`icon-${getIconClass('INTERPRETER', 'operate', schedule.interpreter_status)}`"></i>
                            {{ schedule.interpreter_name }} {{ schedule.interpreter_id }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('SELLER', schedule)">
                            <span class="type_bdg">S</span><i class="icon_box"
                              :class="`icon-${getIconClass('SELLER', 'operate', schedule.seller_status)}`"></i> {{
                                schedule.seller_company }}
                            {{ schedule.seller_id }}
                          </p>
                        </div>
                      </template>
                      <template v-else>
                        <p class="add_schedule"></p>
                      </template>
                    </td>
                  </tr>
                </template>
                <!-- BIS기준 end -->
              </template>
              <template v-else>
                <tr>
                  <td>부스 없음</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
    </div>
    <operate-table-modal :show-status-modal="showStatusModal" :selected-user-data="selectedUserData"
      :show-schedule-add-modal="showScheduleAddModal" :selectedScheduleData="selectedScheduleData"
      @close="closeAllModals"></operate-table-modal>
  </div>
</template>