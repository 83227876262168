// 유저 정보에 대해 저장하는 store
import router from '@/router'
import { useStore } from 'vuex'; // Vuex store에 접근할 수 있게 한다


const userStore = {
    namespaced: true,
    state: () => ({
        isAuthenticated: false,
        email: '',
        accountId: '',
        accountType: '',
        userName: '',
        userCompany: '',
        token: '',
        multitype_user: false // 다중 사용자 유형 여부를 나타내는 상태 변수
    }),
    mutations: {
        login(state, status) {
            state.isAuthenticated = status;
        },
        login_info(state, payload) {
            state.email = payload.email;
            state.accountId = payload.account_id;
            state.accountType = payload.account_type;
            state.userName = payload.full_name;
            state.userCompany = payload.company;
            state.token = payload.token;
        },
        setMultiTypeUser(state, value) {
            state.multitype_user = value; // multitype_user 상태를 설정합니다.
        },
        resetUserState(state) {
            state.email = '';
            state.accountId = '';
            state.accountType = '';
            state.userName = '';
            state.token = '';
            state.isAuthenticated = false;
        }
    },
    actions: {
        logout({ commit }) {
            if (confirm("로그아웃 하시겠습니까?")) {
                // 로그아웃 시 로컬 스토리지에서 값 제거
                localStorage.removeItem('vuex');

                // Vuex state 초기화
                commit('resetUserState');

                // 다른 store의 resetState mutation 호출
                commit('eventStore/resetState', null, { root: true });
                commit('bisStore/resetState', null, { root: true });

                // 홈 페이지로 이동
                router.push({ name: 'home' }).catch(error => {
                    console.log(error);
                });
            }
        }
    }
}

export default userStore;


function isTokenExpired(token) {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const expirationDate = new Date(decodedToken.exp * 1000);
    return expirationDate < new Date();
}