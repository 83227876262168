<script>
import { ref, onMounted, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'surveyDashboard',
  setup() {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const router = useRouter(); // router 인스턴스 사용
    const route = useRoute(); // 현재 라우트 정보 사용
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');

    onMounted(() => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token; // 인증된 경우 토큰 설정
      }
    });

    return {
      eventId, // 사용하고자 하는 변수들을 반환
      isAuthenticated,
      token,
    };
  },
};
</script>

<template>
  <div id="surveyDashboard">
    <div class="" style="padding:100px">
      준비중입니다.
    </div>
  </div>
</template>
