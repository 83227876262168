import { createRouter, createWebHistory, RouterView } from 'vue-router'
import HomeView from '../views/Landing.vue'

// ADMIN
import AppAdmin from '../AppAdmin.vue'

import eventList from '../views/admin/eventList.vue'
import infoDesk from '../views/admin/infoDesk.vue'
import eventListMaster from '../views/admin/setting/eventListMaster.vue'
import eventSetting from '../views/admin/setting/eventSetting.vue'
import eventsettingMaster from '../views/admin/setting/eventSettingMaster.vue'
import eventSettingTables from '../views/admin/setting/eventSettingTables.vue'
import BISSetting from '../views/admin/setting/BISSetting.vue'
import BISQrSetting from '../views/admin/setting/BISQrSetting.vue'
import adminSetting from '../views/admin/setting/adminSetting.vue'
import questionSetting from '../views/admin/setting/questionSetting.vue'
import questionDetail from '../views/admin/setting/questionDetail.vue'
import surveySetting from '../views/admin/setting/surveySetting.vue'
import masterSurveySetting from '../views/admin/setting/masterSurveySetting.vue'
import satisfySetting from '../views/admin/setting/satisfySetting.vue'
import preMatchSetting from '../views/admin/setting/preMatchSetting.vue'
import operateTables from '../views/admin/operation/operateTables.vue'
import operateAttendInterpreter from '../views/admin/operation/operateAttendInterpreter.vue'
import operateAttendSeller from '../views/admin/operation/operateAttendSeller.vue'
import operateAttendQr from '../views/admin/operation/operateAttendQr.vue'
import operateLiveMatch from '../views/admin/operation/operateLiveMatch.vue'
import surveyRecord from '../views/admin/survey/surveyRecord.vue'
import surveyDashboard from '../views/admin/survey/surveyDashboard.vue'
import satisfyRecordInterpreter from '../views/admin/survey/satisfyRecordInterpreter.vue'
import satisfyRecordBuyer from '../views/admin/survey/satisfyRecordBuyer.vue'
import satisfyRecordSeller from '../views/admin/survey/satisfyRecordSeller.vue'
import receptionQr from '../views/admin/reception/receptionQr.vue'
import reception from '../views/admin/reception/reception.vue'
import sendNotice from '@/views/admin/send/sendNotice.vue'
import sendMessage from '@/views/admin/send/sendMessage.vue'

// BIS
import AppBIS from '../AppBIS.vue'

import BISDashboard from '@/views/BIS/dashboard.vue'
import BISMyAccount from '@/views/BIS/myAccount.vue'
import BISNotice from '@/views/BIS/notice.vue'
import BISGlossary from '@/views/BIS/glossary.vue'
import BISSchedule from '@/views/BIS/schedule.vue'
import BISSurvey from '@/views/BIS/survey.vue'
import BISSatisfaction from '@/views/BIS/satisfaction.vue'
// BIS - OUTBOUND
import surveyEmail from '../views/outBound/surveyEmail.vue'
import checkAttend from '../views/outBound/checkAttend.vue'


const routes = [
  {
    path: '/',
    component: AppAdmin, // 기본 레이아웃 설정
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        meta: {
          title: '홈',
        },
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    component: AppAdmin, // admin을 위한 기본 레이아웃 컴포넌트 사용 가능
    children: [
      {
        path: 'event/select',
        name: 'eventList',
        component: eventList,
        meta: {
          title: "이벤트 리스트",
          disableBack: true
        },
      },
      {
        path: 'infodesk',
        name: 'infoDesk',
        component: infoDesk,
        meta: {
          title: "인포데스크",
          disableBack: true
        },
      },
      {
        path: 'event/select/master/:eventId',
        name: 'eventListMaster',
        component: eventListMaster,
        meta: {
          title: "이벤트 리스트",
        },
      },
      {
        path: 'event/setting/new',
        name: 'newEventSetting',
        component: eventSetting,
        meta: {
          title: "이벤트 추가",
        },
      },
      {
        path: 'event/setting/:eventId',
        name: 'eventsetting',
        component: eventSetting,
        meta: {
          title: "이벤트 세팅",
        },
      },
      {
        path: 'event/setting/tables/:eventId',
        name: 'eventSettingTables',
        component: eventSettingTables,
        meta: {
          title: "운영 스케줄 관리",
        },
      },
      {
        path: 'event/setting/master/new',
        name: 'newEventsettingMaster',
        component: eventsettingMaster,
        meta: {
          title: "마스터 이벤트 추가",
        },
      },
      {
        path: 'event/setting/master/:eventId',
        name: 'eventsettingMaster',
        component: eventsettingMaster,
        meta: {
          title: "마스터 이벤트 세팅",
        },
      },
      {
        path: 'event/setting/bis',
        name: 'bisSetting',
        component: BISSetting,
        meta: {
          title: "BIS 관리",
        },
      },
      {
        path: 'event/setting/bis/qr',
        name: 'bisQrSetting',
        component: BISQrSetting,
        meta: {
          title: "BIS QR코드",
        },
        props: true
      },
      {
        path: 'event/setting/admin',
        name: 'adminSetting',
        component: adminSetting,
        meta: {
          title: "어드민 관리",
        },
      },
      {
        path: 'event/setting/question/:eventId',
        name: 'questionSetting',
        component: questionSetting,
        meta: {
          title: "질문지",
        },
      },
      {
        path: 'event/setting/question/:eventId/question_detail/new',
        name: 'newQuestionDetail',
        component: questionDetail,
        meta: {
          title: "질문지 생성",
        },
      },
      {
        path: 'event/setting/question/:eventId/question_detail/:cq_id',
        name: 'questionDetail',
        component: questionDetail,
        meta: {
          title: "질문지 세팅",
        },
      },
      {
        path: 'event/setting/survey/:eventId',
        name: 'surveySetting',
        component: surveySetting,
        meta: {
          title: "설문 구조",
        },
      },
      {
        path: 'event/setting/masterSurvey/:eventId',
        name: 'masterSurveySetting',
        component: masterSurveySetting,
        meta: {
          title: "마스터 설문 구조",
        },
      },
      {
        path: 'event/setting/satisfy/:eventId',
        name: 'satisfySetting',
        component: satisfySetting,
        meta: {
          title: "만족도 조사 설정",
        },
      },
      {
        path: 'event/setting/prematch/:eventId',
        name: 'preMatchSetting',
        component: preMatchSetting,
        meta: {
          title: "사전주선 관리",
        },
      },
      {
        path: 'operate/attend/interpreter',
        name: 'operateAttendInterpreter',
        component: operateAttendInterpreter,
        meta: {
          title: "통역사 출석표",
        },
      },
      {
        path: 'operate/attend/seller',
        name: 'operateAttendSeller',
        component: operateAttendSeller,
        meta: {
          title: "셀러 출석표",
        },
      },
      {
        path: 'operate/attend/qr/:type',
        name: 'operateAttendQr',
        component: operateAttendQr,
        meta: {
          title: "QR촬영",
        },
      },
      {
        path: 'operate/tables',
        name: 'operateTables',
        component: operateTables,
        meta: {
          title: "현장운영",
        },
      },
      {
        path: 'operate/livematch',
        name: 'operateLiveMatch',
        component: operateLiveMatch,
        meta: {
          title: "현장매칭",
        },
      },
      {
        path: 'reception/qr',
        name: 'receptionQr',
        component: receptionQr,
        meta: {
          title: "QR촬영",
        },
      },
      {
        path: 'reception',
        name: 'reception',
        component: reception,
        meta: {
          title: "영접현황",
        },
      },
      {
        path: 'surveyRecord/dashboard',
        name: 'surveyDashboard',
        component: surveyDashboard,
        meta: {
          title: "상담일지 대시보드",
        },
      },
      {
        path: 'surveyRecord/interpreter',
        name: 'surveyRecordInterpreter',
        component: surveyRecord,
        meta: {
          title: "상담일지",
        },
      },
      {
        path: 'surveyRecord/buyer',
        name: 'surveyRecordBuyer',
        component: surveyRecord,
        meta: {
          title: "바이어 상담일지",
        },
      },
      {
        path: 'surveyRecord/seller',
        name: 'surveyRecordSeller',
        component: surveyRecord,
        meta: {
          title: "셀러 상담일지",
        },
      },
      {
        path: 'satisfyRecord/interpreter',
        name: 'satisfyRecordInterpreter',
        component: satisfyRecordInterpreter,
        meta: {
          title: "만족도 조사",
        },
      },
      {
        path: 'satisfyRecord/buyer',
        name: 'satisfyRecordBuyer',
        component: satisfyRecordBuyer,
        meta: {
          title: "만족도 조사",
        },
      },
      {
        path: 'satisfyRecord/seller',
        name: 'satisfyRecordSeller',
        component: satisfyRecordSeller,
        meta: {
          title: "만족도 조사",
        },
      },
      {
        path: 'send/sendNotice',
        name: 'sendNotice',
        component: sendNotice,
        meta: {
          title: "공지사항",
        },
      },
      {
        path: 'send/sendMessage',
        name: 'sendMessage',
        component: sendMessage,
        meta: {
          title: "메세지 전송",
        },
      }
    ]
  },
  {
    path: '/bis',
    name: 'bis',
    component: AppBIS, // bis를 위한 기본 레이아웃 컴포넌트 사용 가능
    children: [
      {
        path: 'dashboard',
        name: 'BISDashboard',
        component: BISDashboard,
        meta: {
          title: "대시보드",
          disableBack: true
        },
      },
      {
        path: 'myAccount',
        name: 'BISMyAccount',
        component: BISMyAccount,
        meta: {
          title: "내 정보",
        },
      },
      {
        path: 'notice',
        name: 'BISNotice',
        component: BISNotice,
        meta: {
          title: "공지사항",
        },
      },
      {
        path: 'glossary',
        name: 'BISGlossary',
        component: BISGlossary,
        meta: {
          title: "무역용어집",
        },
      },
      {
        path: 'survey/:schedule_id',
        name: 'BISSurvey',
        component: BISSurvey,
        meta: {
          title: "상담일지",
        },
      },
      {
        path: 'satisfaction/:event_id',
        name: 'BISSatisfaction',
        component: BISSatisfaction,
        meta: {
          title: "만족도 조사",
        },
      },
      {
        path: 'schedule',
        name: 'BISSchedule',
        component: BISSchedule,
        meta: {
          title: "스케줄",
        },
      },
      {
        path: 'surveyEmail',
        name: 'surveyEmail',
        component: surveyEmail,
        meta: {
          title: "상담일지 이메일",
        },
      },
      {
        path: 'checkAttend',
        name: 'checkAttend',
        component: checkAttend,
        meta: {
          title: "출석확인",
        },
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.disableBack && from.meta.disableBack) {
    alert("잘못된 접근")
    next(false);
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  const title = to.meta.title || 'BIS@T'
  if (title) document.title = 'BIS@T | ' + title
});

export default router
