<script>
import { ref, defineComponent, onMounted, inject, watch, computed, onUnmounted } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  props: {
    showEventModal: Boolean,
    // activeAccountInfo: Object
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');

    const eventListForBIS = ref([])
    async function fetchBISEventList() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/event/read_list_for_bis', {
          params: {
            page: 1,
            limit: 10,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          eventListForBIS.value = response.data.response_data.event_list;
        } else {
          alert('이벤트를 불러오지 못했습니다.');
        }
      } catch (error) {
        // 요청이 실패한 경우
        console.error('이벤트 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }
    const closeModals = () => {
      eventListForBIS.value = []
      emit('close');
    }

    async function routeForBIS(selectEventId, selectEventTitle, selectMasterId) {
      store.commit('eventStore/setEventId', selectEventId);
      store.commit('eventStore/setMasterId', selectMasterId);
      store.commit('eventStore/setEventTitle', selectEventTitle);
      router.push({ name: 'BISDashboard' });
      router.go(0)
      closeModals()
    }

    watch(() => props.showEventModal, (newValue, oldValue) => {
      // 속성 이름을 올바르게 참조
      // eventListForBIS.value = { ...newValue };
      fetchBISEventList();
    }, { deep: true });


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
        fetchBISEventList()
      }
    });

    return {
      eventListForBIS,
      closeModals,
      token,
      eventId,
      routeForBIS
    }
  },
});
</script>

<template>
  <div id="selectEvent">
    <div class="overlay" v-if="showEventModal"></div>
    <transition name="slide-up">
      <div class="bottom_sheet" v-if="showEventModal">
        <div class="sheet_header">
          <p class="icon_box" @click="closeModals"><i class="icon-delete"></i></p>
        </div>
        <div class="sheet_content">
          <p class="size_20 b_700">다른 상담회 보기</p>
          <p class="size_14 sub_text">현재 참여중인 다른 상담회 리스트예요. </p>
          <div class="event_list_wrap">
            <div class="event_list" v-for="item in eventListForBIS" :key="item.event_id"
              @click="routeForBIS(item.event_id, item.title, item.master_event_id)">
              <p>{{ item.title }}</p><i class="icon-checked" :class="{ 'active': item.event_id === eventId }"></i>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
