<script>
import { ref, onMounted, computed, inject } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import Pagination from "@/components/admin/pagination.vue";
import subHeader from "@/components/admin/subHeader.vue";
import tabHeader from "@/components/admin/tabHeader.vue";
import { return_language } from "@/assets/js/survey_form";
import questionPreviewModal from "@/components/admin/questionPreviewModal.vue";

export default {
  name: "questionSetting",
  components: {
    Pagination,
    subHeader,
    tabHeader,
    questionPreviewModal,
  },
  setup() {
    const axios = inject("axios"); // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const eventId = computed(() => store.state.eventStore.eventId);
    const userName = ref("");
    const token = ref("");
    const activeSub = ref("survey"); // 여기에서 'setting'이라는 값을 정의합니다.
    const activeHeadTab = ref("satisfy"); // 여기에서 'question'이라는 값을 정의합니다.


    const recordList = ref([]);
    const readRecordList = async () => {
      try {
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/satisfaction/read_record_list`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          params: {
            piece_event_id: eventId.value,
            sr_type: "INTERPRETER",
            start_date: startDate.value,
            end_date: endDate.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        recordList.value = response.data.record_list;
        recordList.value.map(r => {
          r['cr_status'] = 0;
        })
      } catch (error) {
        console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
      }
    }

    const returnComma = money => {
      return money.toLocaleString("ko-KR")
    }

    const returnDate = date => {
      const days = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
      let value = date.split('T')[0];
      let day = new Date(value).getDay();
      return `${value} ${days[day]}`;
    }

    const returnTime = date => {
      return date.substring(11, 16);
    }

    const startDate = ref();
    const endDate = ref();
    //DATE SELECT
    const masksDate = ref({
      modelValue: 'YYYY-MM-DD',
    });
    // 사이드 팝업
    const showPreviewModal = ref({
      onoff: false,
      selected: "",
    });
    const showQuestionPreview = (cq_id) => {
      if (showPreviewModal.value.selected == cq_id) {
        closeModals();
      } else {
        showPreviewModal.value.onoff = true; // question preview 모달 표시
        showPreviewModal.value.selected = cq_id;
      }
    };
    const closeModals = () => {
      showPreviewModal.value.onoff = false; // question preview 모달 표시
      showPreviewModal.value.selected = "";
    };

    // 추가질문 상세, 생성
    const routerPush = (cq_id) => {
      if (cq_id == "new") {
        router.push({ name: "newQuestionDetail" });
      } else if (typeof cq_id == "number") {
        router.push({ name: "questionDetail", params: { cq_id: cq_id } });
      } else {
        return false;
      }
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: "home" }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        userName.value = store.state.userStore.userName;
        token.value = store.state.userStore.token;
      }
    });
    return {
      isAuthenticated,
      userName,
      activeSub,
      activeHeadTab,
      recordList,
      showPreviewModal,
      showQuestionPreview,
      closeModals,
      routerPush,
      startDate,
      endDate,
      masksDate,
    };
  },
};
</script>

<template>
  <div id="record" class="back_light_gray2">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
      </div>
      <div class="tables_wrap">
        <div class="function_bar">
          <div class="row al_center">
            <div class="date_picker_wrap">
              <VDatePicker v-model.string="startDate" mode="date" :masks="masksDate">
                <template #default="{ togglePopover }">
                    <div class="date_picker" @click="togglePopover">
                      <p class="">{{ startDate ? startDate : "YYYY-MM-DD" }}</p>
                      <i class="icon-down_arrow"></i>
                    </div>
                </template>
              </VDatePicker>
              <span>~</span>
              <VDatePicker v-model.string="endDate" mode="date" :masks="masksDate">
                <template #default="{ togglePopover }">
                  <div class="date_picker" @click="togglePopover">
                    <p class="">{{ endDate ? endDate : "YYYY-MM-DD" }}</p>
                    <i class="icon-down_arrow"></i>
                  </div>
                </template>
              </VDatePicker>
            </div>
            <div class="user_table_top_cont_wrap">
              <div class="user_table_top_cont ds_flex al_center">
                <p class="icon_box"><i class="icon-download"></i></p>
                <div class="search"><input type="text" /><i class="icon-search"></i></div>
              </div>
            </div>
          </div>
          <div class="row"></div>
        </div>
        <div class="table_wrap">
          <table class="table_view">
            <thead>
              <tr>
                <th>작성자</th>
                <th>연락처</th>
                <th>제출 일시</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="recordList.length > 0">
                <tr v-for="r in recordList" :key="r.cr_id">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <question-preview-modal :show-preview-modal="showPreviewModal" @close="closeModals"></question-preview-modal>
    </div>
  </div>
</template>
