<script>
import { ref, onMounted, onBeforeUnmount, computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router'; // useRouter를 추가
import { useStore } from 'vuex';

export default {
  setup() {
    const axios = inject('axios'); // $axios 주입 받기
    const route = useRoute();
    const router = useRouter(); // router 인스턴스 가져오기
    const store = useStore();
    const windowSize = ref({
      width: 0,
      height: 0
    });

    // 유저 관련 데이터 및 계산된 속성 정의
    const isAuthenticated = ref(false);
    const token = ref('');
    const userId = computed(() => store.state.userStore.accountId);
    const userName = computed(() => store.state.userStore.userName);
    const userType = computed(() => store.state.userStore.accountType);
    const userCompany = computed(() => store.state.userStore.userCompany);

    // 이벤트 관련 데이터 정의
    const eventTitle = computed(() => store.state.eventStore.eventTitle);
    const eventId = computed(() => store.state.eventStore.eventId);
    const masterId = computed(() => store.state.eventStore.masterId);
    const eventDate = computed(() => store.state.eventStore.eventDate);
    const scheduleDate = ref([]);
    const selectDate = ref(eventDate.value);
    const accountStatus = ref({});

    // 현재 윈도우 크기 가져오기
    function updateWindowSize() {
      windowSize.value = {
        width: window.innerWidth,
        height: window.innerHeight
      };
    }

    // 사용자 유형 포맷팅 함수 정의
    function userTypeFormmat() {
      switch (userType.value) {
        case 'INTERPRETER':
          return "통역사";
        case 'SELLER':
          return "셀러";
        case 'BUYER':
          return "바이어";
        default:
          return "-";
      }
    }
    const formattedUserType = computed(() => userTypeFormmat(userType.value));

    // Fetch Own Date
    async function fetchEvent() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/event/read_event_own_date', {
          params: {
            piece_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          scheduleDate.value = response.data.own_date
          if (!selectDate.value) {
            selectDate.value = response.data.own_date[0]
            console.log(selectDate.value)
          }
          await fetchSchedule()
        } else {
          console.log("이벤트 로드 실패")
        }
      } catch (error) {
      }
    }

    // 공통함수
    async function fetchSchedule() {
      try {
        const response = await axios.get('/user/read_account_status_for_bis', {
          params: {
            piece_event_id: eventId.value,
            date: selectDate.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          accountStatus.value = response.data.account_status;
        } else {
          console.log("스케줄 로드 실패");
        }
      } catch (error) {
        console.error("스케줄 로드 오류:", error);
      }
    }


    function handleSelectDate() {
      store.commit('eventStore/setEventDate', selectDate.value);
      router.go(0)
    }

    const user = {
      userId,
      userName,
      userCompany,
      userType,
      formattedUserType,
      accountStatus
    };

    const event = {
      eventTitle,
      eventId,
      masterId,
      eventDate,
      scheduleDate,
      handleSelectDate,
      selectDate
    };

    onMounted(async () => {
      updateWindowSize();
      window.addEventListener('resize', updateWindowSize);

      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (isAuthenticated.value) {
        token.value = store.state.userStore.token;
        await fetchEvent();
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateWindowSize);
    });

    return {
      window: windowSize,
      user,
      event
    };
  }
};
</script>
<template>
  <div class="primary_body">
    <!-- <common-bis-header :window="window"></common-bis-header>
    <router-view class="main_content bis" :class="{ 'mobile': window.width < 769 }" :window="window"></router-view> -->
    <common-bis-header :window="window" :user="user" :event="event" />
    <router-view class="main_content bis" :class="{ 'mobile': window.width < 769 }"
      :window="window" :user="user" :event="event" />
  </div>
</template>