<script>
import { ref, defineComponent, onMounted, inject, watch, computed, onUnmounted } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';
import { useToast } from 'vue-toastification';

export default defineComponent({
  props: {
    showSurveyInform: Boolean,
    showSurveyReject: Boolean,
    rejectReason: String,
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const toast = useToast(); // Toast 호출 함수
    const isAuthenticated = ref(false);
    const token = ref('');

    // accountId가 변경될 때 storedAccountId도 업데이트
    // watch(() => props.showSurveyInform, (newValue, oldValue) => {
    //   // 속성 이름을 올바르게 참조
    //   props.showSurveyInform = { ...newValue };
    //   // newAccount_type.value = newValue.user_type;
    // }, { immediate: true });

    const closeModals = () => {
      emit('close');
    }

    const submitRecord = () => {
      emit('submit');
    }

    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
        // if (props.showSurveyInform) {
        // }
      }
    });

    return {
      closeModals,
      submitRecord,
    }
  },
});
</script>

<template>
  <div id="surveyInform">
    <div class="overlay" v-if="showSurveyInform || showSurveyReject"></div>
    <transition name="slide-up">
      <div class="bottom_sheet">
        <template v-if="showSurveyInform">
          <div class="sheet_content">
            <p class="title t_center">상담일지를 제출할까요?</p>
            <p class="b_500 sub_text t_center">제출한 상담일지는 다시 열람할 수 없어요.</p>
            <p class="b_500 sub_text t_center">단, 수정이 필요한 경우에 상담일지가 반려될 수 있어요.</p>
            <div class="btn_wrap duo">
              <div class="inline_main_btn cancel_btn b_500" @click="closeModals()">취소</div>
              <div class="inline_main_btn active_btn b_500" @click="submitRecord()">제출하기</div>
            </div>
          </div>
        </template>
        <template v-if="showSurveyReject">
          <div class="sheet_content">
            <p class="title">상담일지 반려</p>
            <p class="size_14 b_500 sub_text">반려사유를 확인하세요.</p>
            <div class="reject_inner">
              {{ rejectReason }}
            </div>
            <div class="btn_wrap">
              <div class="block_main_btn active_btn b_500" @click="closeModals()">상담일지 수정하기</div>
            </div>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>
